import React from "react"

import Layout from "../components/layout"
import { FooterAlternate1 } from "../components/footer"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="About SalesTribe" />

    <article className="article">
        <header className="header header--basic article__header">
            <div className="header__content">
                <div className="container">
                    <div className="row">
                        <div className="header__inner col-md-9 col-lg-7 col-lg-offset-1">
                            <h3 className="header__category">About</h3>
                            <h1 className="header__title">About SalesTribe</h1>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className="container" id="article-content">
            <div className="row">
                <div className="article__body col-sm-12 col-md-8 col-lg-offset-1 col-lg-11">
                    <div className="rich-text">
                       <p>What’s the number #1 challenge facing businesses today? ​<br></br>
                        Answer – gaining the attention of an educated B2B buyer who no longer wants to meet you in person. </p>
                        <p>Founded in 2017, SalesTribe has become a world leader in Digital Sales Enablement – we help businesses (and our global community of salespeople - The Tribe) transform to meet the Digital-First, multi-channel engagement that modern B2B buyers now expect. Buyers are evolving and we help your sales & marketing departments evolve with them.</p>
                        <p>We empower our clients to lead the future of continuous innovation by embracing Digital Sales Enablement.  Our secret is blending world-class Sales Enablement (consulting & training) with integrated Sales & Marketing services, all in-house and underpinned by the SalesTribe Academy and our Online Community. ​</p>
                        <p>
                        <b>“B2B decision maker preferences and behaviors have shifted dramatically since the onset of COVID. The go-to-market revolution is here and B2B sales is changed forever” – McKinsey.</b>
                        </p>
                        <p>
                            Being Digital-First and Data-Led in how you engage your target market is now the only way to survive and thrive in the global, digital, connected economies. Our research-based approach will equip your team to understand modern buyer behavior and then implement effective Sales Enablement strategies to succeed.​
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </article>

    

  </Layout>
)

export default IndexPage